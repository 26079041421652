<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          label="Search"
          prepend-icon="mdi-magnify"
          @input="fetchCommissions"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openCreateDialog">
          Create Commission
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="commissions"
        :search="search"
        :loading="loading"
        :hide-default-footer="true"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editCommission(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteCommission(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <p>
            <span color="primary">{{ item.createdAt | getFormattedDate }}</span>
          </p>
        </template>
      </v-data-table>
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="fetchCommissions"
      ></v-pagination>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >{{ isEdit ? "Edit" : "Create" }} Commission</span
            >
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <p>Select the broker you want to apply the commission to</p>

              <new-consultant-picker
                @consultant-selected="handleBrokerSelected"
              />
              <p>Select the concerning client</p>

              <v-autocomplete
                :items="consultantClients"
                label="Clients"
                :loading="loading"
                :item-text="getFullName"
                item-value="id"
                @change="handleClientSelected"
                outlined
              ></v-autocomplete>

              <v-text-field
                v-model="formData.product"
                label="Product"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="formData.commission"
                label="Commission"
                :rules="[rules.required, rules.number]"
                required
                outlined
              ></v-text-field>

              <v-text-field
                v-model="formData.converted"
                label="Converted"
                :rules="[rules.number]"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="formData.premiumVolume"
                label="Premium Volume"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="formData.averagePremium"
                label="Average Premium"
                :rules="[rules.number]"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="formData.source"
                label="Source"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="formData.type"
                label="Type"
                placeholder="e.g. Monthly"
                outlined
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveCommission">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import NewProductPicker from "../../../components/Base/Pickers/NewProductPicker.vue";
import ClientPicker from "../../../components/Base/Pickers/ClientPicker.vue";
import { GET_USERS } from "../../../../queries";
import NewConsultantPicker from "../../../components/Base/Pickers/NewConsultantPicker.vue";

export default {
  name: "ViewCommissions",
  components: {
    NewProductPicker,
    ClientPicker,
    NewConsultantPicker,
  },
  data() {
    return {
      search: "",
      options: {},
      headers: [
        { text: "Broker", value: "consultantName" },
        // { text: "Client", value: "clientName" },
        { text: "Commission", value: "commission" },
        { text: "Converted", value: "converted" },
        { text: "Premium Volume", value: "premiumVolume" },
        { text: "Average Premium", value: "averagePremium" },
        // { text: "Source", value: "source" },
        // { text: "Type", value: "type" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      commissions: [],
      consultantClients: [],
      totalRecords: 0,
      page: 1,
      itemsPerPage: 10,
      pageCount: 0,
      loading: false,
      dialog: false,
      isEdit: false,
      formData: {
        id: "",
        broker: "",
        product: "",
        client: "",
        commission: 0,
        converted: null,
        premiumVolume: "",
        averagePremium: null,
        source: "",
        type: "",
      },
      valid: false,
      rules: {
        required: (value) => !!value || "Required.",
        number: (value) =>
          value === null ||
          value === "" ||
          !isNaN(value) ||
          "Must be a number.",
      },
    };
  },
  methods: {
    async fetchCommissions() {
      this.loading = true;

      const skip = (this.page - 1) * this.itemsPerPage;
      let query = {};

      if (this.search.length > 1) {
        query = {
          $or: [
            { consultantName: { $regex: this.search, $options: "i" } },
            {
              commission: isNaN(this.search)
                ? undefined
                : parseFloat(this.search),
            },
          ].filter(
            (condition) =>
              condition.commission !== undefined ||
              condition.clientName ||
              condition.consultantName
          ),
        };
      }

      try {
        const response = await this.$apollo.query({
          query: gql`
            query Commissions($limit: Int!, $skip: Int!, $query: JSON!) {
              commissions(limit: $limit, skip: $skip, query: $query) {
                records {
                  id
                  broker
                  client
                  product
                  commission
                  consultantName
                  clientName
                  converted
                  premiumVolume
                  averagePremium
                  source
                  type
                  createdAt
                }
                count
              }
            }
          `,
          variables: {
            limit: this.itemsPerPage,
            skip,
            query,
          },
          fetchPolicy: "network-only",
        });

        this.commissions = response.data.commissions.records;
        this.totalRecords = response.data.commissions.count;
        this.pageCount = Math.ceil(this.totalRecords / this.itemsPerPage);
      } catch (error) {
        console.error("Error fetching commissions:", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchData() {
      this.isLoading = true;
      const query = {
        consultant: this.formData.broker,
      };
      const page = 1;
      const limit = 100;

      try {
        const response = await this.$apollo.query({
          query: GET_USERS,
          variables: {
            query,
            page,
            limit,
          },
        });

        const records = response.data.users.records;
        if (Array.isArray(records)) {
          this.consultantClients = records;
        } else {
          console.error("Expected an array, got:", typeof records);
          this.consultantClients = [];
        }
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.isLoading = false;
      }
    },

    handleProductSelected(product) {
      this.formData.product = product;
    },

    getFullName(item) {
      if (item && item.firstName && item.lastName) {
        return `${item.firstName} ${item.lastName}`;
      }
      return "";
    },

    async handleBrokerSelected(broker) {
      this.formData.broker = broker;
      await this.onConsultantSelected();
    },

    handleClientSelected(clientId) {
      this.formData.client = clientId;
    },

    openCreateDialog() {
      this.isEdit = false;
      this.formData = {
        id: "",
        broker: "",
        product: "",
        client: "",
        commission: 0,
        converted: null,
        premiumVolume: "",
        averagePremium: null,
        source: "Medshield",
        type: "Monthly",
      };
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    async saveCommission() {
      if (this.$refs.form.validate()) {
        try {
          const formattedCommission = {
            ...this.formData,
            commission: parseInt(this.formData.commission, 10),
            converted:
              this.formData.converted === ""
                ? null
                : parseInt(this.formData.converted, 10),
            averagePremium:
              this.formData.averagePremium === ""
                ? null
                : parseInt(this.formData.averagePremium, 10),
          };

          const mutation = this.isEdit
            ? gql`
                mutation UpdateCommission($commission: CommissionUpdateInput!) {
                  updateCommission(commission: $commission) {
                    id
                    updated
                    commission {
                      id
                    }
                  }
                }
              `
            : gql`
                mutation CreateCommission($commission: CommissionCreateInput!) {
                  createCommission(commission: $commission) {
                    id
                  }
                }
              `;

          const variables = this.isEdit
            ? {
                commission: {
                  ...formattedCommission,
                  consultantName: undefined,
                  clientName: undefined,
                  createdAt: undefined,
                  __typename: undefined,
                },
              }
            : { commission: { ...formattedCommission, id: undefined } };

          if (!this.isEdit) {
            delete variables.commission.id;
          }

          await this.$apollo.mutate({
            mutation,
            variables,
          });

          this.$swal({
            title: "Success",
            text: "Commission has been saved successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            this.fetchCommissions();
          });

          this.dialog = false;
        } catch (error) {
          console.error(error);
        }
      }
    },

    editCommission(item) {
      // Prepopulate formData with the existing item data
      this.isEdit = true;
      this.formData = { ...item };
      this.dialog = true;
    },

    async onConsultantSelected() {
      await this.fetchData();
    },

    async deleteCommission(item) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation DeleteCommission($id: ID!) {
              deleteCommission(id: $id) {
                id
                deleted
              }
            }
          `,
          variables: { id: item.id },
        });
        this.$swal({
          title: "Information",
          text: "Commission has been successfully deleted!",
          icon: "info",
          confirmButtonText: "OK",
        }).then(() => {
          this.fetchCommissions();
        });
      } catch (error) {
        console.error(error);
      }
    },
  },

  created() {
    this.fetchCommissions();
  },

  filters: {
    getFormattedDate(value) {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleString();
    },
  },
};
</script>

<style scoped>
.v-data-table {
  min-height: 400px;
}
</style>
